
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {AUTH_STORE_NAME} from "@/store/auth.store";
import {namespace} from "vuex-class";
import {APPLICATION_STORE_NAME, ApplicationStoreMutations} from "@/store/application.store";
import Company from "@/models/Company";
import CompanyRepository from "@/api/repositories/CompanyRepository";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    updatingCompany: {
      name: {
        required
      },
      legalName: {
        required
      },
    }
  }
})
export default class EditCompanyDetailsComponent extends mixins(ErrorMessageHandlerMixin) {
  @ApplicationStore.Mutation(ApplicationStoreMutations.SET_COMPANY)
  private setCompany!: (company: Company) => void;

  @Prop({required: true})
  private company!: Company;

  private updatingCompany: Company = new Company();

  created() {
    this.updatingCompany = Company.parseFromObject({...this.company});
  }

  /**
   * State Bool for a Valid form
   * @private
   */
  private isValid = true;

  /**
   * State Bool for a Loading Indicator
   * @private
   */
  private isLoading = false;

  /**
   * is called when the user clicks on the save button, validates the inputs and tries to change the password of the
   * user
   * @private
   */
  private async saveData() {
    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      // tries to change the password in the api
      this.isLoading = true;
      const response = await CompanyRepository.updateCompany(this.updatingCompany.id,this.updatingCompany);
      const updatedCompany = Company.parseFromObject({...response.data});
      this.setCompany(updatedCompany);

      // Show Success Message
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_NAME_SUCCESS');
      this.dismiss(true);
    } catch (_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_NAME_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }
}
